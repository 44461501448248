// Responsive tables
// wrap tables in richText in a div for styling
const tables = document.querySelectorAll('.wysiwyg-content table')
if (tables.length) {
  if (!('length' in tables)) tables = [tables]
  for (let i = 0; i < tables.length; i += 1) {
    const table = tables[i]

    const div = document.createElement('div');
    div.className = 'table-container';
    div.style.width = '100%';
    div.style.overflow = 'auto';

    table.parentNode.insertBefore(div, table)
    table.parentNode.removeChild(table)
    div.appendChild(table)
  }
}