<template>
  <div class="relative -mx-5 xxl:-mx-10 flex flex-wrap" :style="{ minHeight: isLoading ? listMinHeight() : 'auto' }">
    <transition name="fade" mode="out-in">
      <template v-if="items.length > 0 && !isLoading">
        <ul class="small-block-grid-1 medium-block-grid-3 large-block-grid-4 xlarge-block-grid-6 result-list" data-equalizer="product-result">
          <category-search-item
            v-for="(item, key) in items"
            :key="key"
            :item="item"
            :is-loading="isLoading"
          ></category-search-item>
        </ul>
      </template>
    </transition>
    <transition name="fadeIn" mode="in-out">
      <template v-if="(!_.isEmpty(query) && !isLoading && _.size(items) === 0 && isReady) || (selectedCategories.length > 0 && !isLoading && _.size(items) === 0 && isReady)">
        <h4 class="no-results-title heading-4 mx-auto py-8 text-center">{{"No products found"|t}}</h4>
      </template>
    </transition>
  </div>
</template>
<script>
import Vue from 'vue';
import {translation} from '../../js/translation'
import CategorySearchItem from './CategorySearchItem'
import CardLoadingIndicator from './CardLoadingIndicator'
import _ from 'lodash'

export default {
  name: 'CategorySearchList',
  props: {
    sectionType: {
      type: Array,
      default: []
    },
    items: {
      type: Array,
      default: [],
    },
    elementsPerPage: {
      type: Number,
      default: 0
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    isReady: {
      type: Boolean,
      default: false
    },
    query: {
      type: String,
      default: '',
    },
    selectedCategories: {
      type: Array,
      default: () => [],
    }
  },
  components: {
    CategorySearchItem,
    CardLoadingIndicator,
  },
  filters: {
    t(val) {
      const locale = document.getElementById('productSearchApp').dataset.locale || 'fi-FI';
      const trans = translation[locale];
      return trans[val] || val;
    },
  },
  methods: {
    listMinHeight: function() {
      let rowHeight = 310;
      let rowAmount = 2;
      return rowAmount * rowHeight + 'px';
    }
  },
  updated: function () {
    const resultsListElement = document.querySelector('.result-list');
    if (resultsListElement) {
      $(document).foundation('equalizer', 'reflow');

      if (localStorage.productScroll != null) {
        window.scrollTo({
          top: localStorage.productScroll,
        })
        localStorage.productScroll = null;
      }
    }
  },
  computed: {
    _() {
      return _
    }
  },
};
</script>
