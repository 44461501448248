export const translation = {
  "fi-FI": {
    "HI": "MOI",
    "All categories": "Kaikki kategoriat",
    "Selected categories": "Valitut kategoriat",
    "Loading": "Ladataan",
    "View results": "Näytä tulokset",
    "Clear everything": "Tyhjennä kaikki",
    "Clear search": "Poista rajaukset",
    "Search recipes": "Hae reseptejä",
    "Search products": "Hae tuotteita",
    "Read more": "Lue lisää",
    "No results found": "Ei tuloksia",
    "No products found": "Harmi, hakemaasi tuotetta ei löydy. Kokeile uutta hakua.",
    "of": "yhteensä",
    "Sort by": "Lajitella",
    "Newest first": "Uusin ensin",
    "Oldest first": "Vanhin ensin",
    "Title A-Z": "Otsikko A-Z",
    "Title Z-A": "Otsikko Z-A",
    "Filter": "Rajaa",
    "Recipes": "reseptiä",
    "Show all": "Näytä kaikki",
    "Searching": "Etsitään...",
    "products": "tuotetta"
  },
  "sv-FI": {
    "HI": "Hej",
    "All categories": "Alla kategorier",
    "Selected categories": "Valda kategorier",
    "Loading": "lastning",
    "View results": "Visa resultat",
    "Clear everything": "Rensa allt",
    "Clear search": "Rensa",
    "Search recipes": "Sök recept",
    "Search products": "Sök produkter",
    "Read more": "Läs mer",
    "No results found": "Inga resultat hittades",
    "No products found": "Inga resultat hittades",
    "of": "av",
    "Sort by": "Sort by",
    "Newest first": "Nyaste först",
    "Oldest first": "Äldst först",
    "Title A-Z": "Titel A-Z",
    "Title Z-A": "Titel Z-A",
    "Filter": "Rajaa",
    "Recipes": "reseptiä",
    "Show all": "Visa alla",
    "Searching": "Sökande..."
  },
  "en-US": {
    "HI": "Hello"
  },
};