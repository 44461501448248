import Vue from 'vue'
import axios from 'axios'
import _ from 'lodash'
import {configureApi, apiUrl, getQueryParam, executeQuery} from './searchCommon';
import {translation} from './translation'

import CategorySearchMeta from '../vue/CategorySearchMeta'
import CategorySearchList from '../vue/CategorySearchList'
import Pagination from '../vue/Pagination'
import CategoryFilter from '../vue/CategoryFilter'

const searchApiToken = window.recipeSearchGraphQLApiKey || '';

// Site search application
function getcategorySearchLandingData() {
  return {
    allCategories: [],
    sectionType: '',
    searchApi: axios.create(configureApi(apiUrl, searchApiToken)),
    searchQuery: '',
    searchCategory: {},
    searchCategories: [],
    searchResults: [],
    searchSorting: 'postdate desc',
    filtersOpen: false,
    pagination: {
      totalCount: 0,
      pages: [],
      hasNext: false,
      hasPrev: false,
      nextPageUrl: '',
      prevPageUrl: '',
      elementsPerPage: 100,
      currentPage: 1
    },
    isLoading: false,
    isReady: false,
    showAll: false
  };
}

var filterChangeTimer;

var searchApp = function() {

// What to search for
const searchSite = [document.getElementById('searchApp').dataset.site];
const searchSections = JSON.parse(document.getElementById('searchApp').dataset.section);
const searchEntries = ['recipes_recipes_Entry'];
const searchTransform = JSON.parse(document.getElementById('searchApp').dataset.transform);

let searchQueries = ''
_.each(searchEntries, (entryType) => {
  searchQueries = searchQueries + 
      `
      ... on ${entryType} {
        id
        title
        url
        activeCookingTime
        passiveCookingTime
        postDate
        picture {
          title
          url(height:600,width:800,quality:75,position:"center")
        }
        recipeProperties {
          title
          ... on recipeProperties_Category {
            id
            abbreviation
          }
        }
        easy
        ${searchTransform.join('\n')}
      }`
});

// The query to search for entries in Craft
const searchQuery =
  `
  query searchQuery($needle: String, $limit: Int, $offset: Int, $orderBy: String, $site: [String], $relatedTo: [QueryArgument], $sections: [String], $recipeProperties: [QueryArgument], $dishTypes: [QueryArgument], $recipeThemes: [QueryArgument], $mainIngredients: [QueryArgument])
    {
      entries( limit: $limit offset: $offset orderBy: $orderBy search: $needle site: $site relatedTo: $relatedTo section: $sections recipeProperties: $recipeProperties dishTypes: $dishTypes recipeThemes: $recipeThemes mainIngredients: $mainIngredients) {
        ${searchQueries}
      }
      entryCount(limit: $limit offset: $offset orderBy: $orderBy search: $needle site: $site relatedTo: $relatedTo section: $sections recipeProperties: $recipeProperties dishTypes: $dishTypes recipeThemes: $recipeThemes mainIngredients: $mainIngredients)
    }
  `;

  new Vue({
    el: document.getElementById('searchApp'),
    delimiters: ['<%', '%>'],
    // Here we can register any values or collections that hold data
    data: getcategorySearchLandingData(),
    components: {
      CategorySearchList,
      CategorySearchMeta,
      Pagination,
      CategoryFilter,
    },
    beforeCreate: function() {
    },
    created: function() {
      // this.selected.location = !!$eventLanding.data('location') && $eventLanding.data('location');

      let categoryIds = [];
      const searchParam = getQueryParam('q');
      const categoryParam = getQueryParam('cat');
      const categoryIdParam = getQueryParam('cat-id');
      const pageParam = getQueryParam('page');
      const showAllParam = getQueryParam('show-all');

      if (!!showAllParam) {
        this.showAll = true;
        this.performSearch();
      }

      if (!!searchParam) {
        this.searchQuery = searchParam;
      }
      if(!!categoryParam) {
        // this.searchCategory.slug = categoryParam;
      }
      if(!!categoryIdParam) {
        const categoryIds = categoryIdParam.map(id => parseInt(id));
        this.searchCategories = categoryIds;
      }
      if (!!pageParam) {
        this.pagination.currentPage = parseInt(pageParam);
      }
      this.sectionType = searchSections;
      window.addEventListener('click', (e) => {
        const filtersModal = document.getElementById('filters');
        if (filtersModal && !e.target.closest('.filters') && !e.target.closest('.filters-toggle') && !e.target.closest('.clear-filters')){
          this.filtersOpen = false;
        }
      })
    },
    mounted: function() {
      if (!_.isEmpty(this.searchQuery)) {
        this.performSearch();
      } else {
        if (!_.isEmpty(this.searchCategories)) {
          this.performSearch();
        }
      }

      // Focus style
      const elements = document.querySelectorAll("a, button, input[type='button'], select");
      let mouseDown = false;

      for (let i = 0; i < elements.length; i++) {
        elements[i].addEventListener('mousedown', () => {
          mouseDown = true;
        });
        
        elements[i].addEventListener('mouseup', () => {
          mouseDown = false;
        });
        
        elements[i].addEventListener('focus', (event) => {
          if (mouseDown) {
            event.target.blur();
          }
        });
      }
    },
    updated: function() {
      var button = document.querySelectorAll('.tooltip-container-vue');
      button.forEach(element => {
        if (!element.classList.contains('tooltip-action-set')) {
          element.classList.add('tooltip-action-set')
          $(element).hover(function () {
            var $this = $(this);
            $this.toggleClass('active');
          });

        }
      });

      // recipes swiper
      const swiperRecipes = new Swiper('.swiper-recipes', {
        // Optional parameters
        autoHeight: false,
        spaceBetween: 24,
        slidesPerView: 'auto',
        
        // Navigation arrows
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },

        // And if we need scrollbar
        scrollbar: {
          el: '.swiper-scrollbar',
          draggable: true
        },
      });

      // recipes swiper
      const swiperArticles = new Swiper('.swiper-articles', {
        // Optional parameters
        autoHeight: false,
        spaceBetween: 24,
        slidesPerView: 'auto',

        // Navigation arrows
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },

        // And if we need scrollbar
        scrollbar: {
          el: '.swiper-scrollbar',
          draggable: true
        },
      });

      // recipes swiper
      const swiperLiftups = new Swiper('.swiper-liftups', {
        // Optional parameters
        autoHeight: false,
        spaceBetween: 24,
        slidesPerView: 'auto',  

        // Navigation arrows
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },

        // And if we need scrollbar
        scrollbar: {
          el: '.swiper-scrollbar',
          draggable: true
        },
      });

      // large card swiper
      const swiper = new Swiper('.swiper-large-cards', {
        // Optional parameters
        spaceBetween: 24,
        slidesPerView: 'auto',
        // Navigation arrows
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },

        // And if we need scrollbar
        scrollbar: {
          el: '.swiper-scrollbar',
          draggable: true
        },
      });
      
      // recipes swiper
      const swiperProducts = new Swiper('.swiper-products', {
        // Optional parameters
        autoHeight: false,
        spaceBetween: 24,
        slidesPerView: 'auto',

        // Navigation arrows
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },

        // And if we need scrollbar
        scrollbar: {
          el: '.swiper-scrollbar',
          draggable: true
        },
      });
    },
    destroyed: function destroyed() {
    },
    watch: {
      'pagination.totalPages': function (val) {
        this.updatePagination();
      },
      'pagination.currentPage': function (val) {
        this.scrollup();
        this.pagination.offset = (this.pagination.currentPage - 1) * this.pagination.elementsPerPage;
        this.performSearch();
      },
      searchQuery: function(val, oldVal) {
        if(val !== oldVal) {
          this.isReady = false
        }
      },
      searchCategory: {
        handler: function(val, oldVal) {
          if(!!oldVal && val.id !== oldVal.id) {
            // this.meta = _.get(getcategorySearchLandingData(), 'meta');
          }
          this.performSearch();
        },
        deep: true
      },
      searchCategories: {
        handler: function(val, oldVal) {
          if(!!oldVal && JSON.stringify(val) !== JSON.stringify(oldVal)) {
            // this.meta = _.get(getcategorySearchLandingData(), 'meta');
            if (!_.isEmpty(val)) {
              this.performSearch();
            } else {
              if (!_.isEmpty(this.searchQuery)) {
                this.performSearch();
              } else {
                this.clearSearch();
              }
            }
          }
        },
        deep: true
      },
      searchSorting: function() {
        this.scrollup();
        this.performSearch();
      },
    },
    filters: {
      t: function(val) {
        const locale = document.getElementById('searchApp').dataset.locale || 'fi-FI';
        const trans = translation[locale];
        return trans[val] || val;
      },
    },
    computed: {
    },
    methods: {
      clearSearch() {
        this.searchResults = [];
        let pageUrl = location.protocol + '//' + location.host + location.pathname;
        history.replaceState(null, null, pageUrl);
        this.pagination = _.get(getcategorySearchLandingData(), 'pagination');
      },
      performSearch(showAll) {
        let self = this;

        let searchTitle = !!self.searchQuery ? `${self.searchQuery}` : '';

        // let searchCategoriesArray = [];
        // if (!_.isEmpty(self.searchCategories)) {
        //   _.map(self.searchCategories, o => {
        //     searchCategoriesArray.push({targetElement: o.id})
        //   })
        // }

        let dishTypes = [];
        let mainIngredients = [];
        let recipeProperties = [];
        let recipeThemes = [];

        if (!_.isEmpty(self.searchCategories)) {
          self.searchCategories.forEach(category => {
            if (category.parentCategory === 'dishTypes') {
              dishTypes.push(category.id);
            }
            if (category.parentCategory === 'mainIngredients') {
              mainIngredients.push(category.id);
            }
            if (category.parentCategory === 'recipeProperties') {
              recipeProperties.push(category.id);
            }
            if (category.parentCategory === 'recipeThemes') {
              recipeThemes.push(category.id);
            }
          });
        }

        let relatedTo = [];


        // Set the variables we will pass in to our query
        let variables = {
            sections: searchSections,
            needle: searchTitle ? `title:${searchTitle} OR description:${searchTitle} OR ingredientMatrix:${searchTitle}` : '',
            limit: self.pagination.elementsPerPage || 24,
            offset: (self.pagination.currentPage - 1) * self.pagination.elementsPerPage,
            orderBy: self.searchSorting,
            site: searchSite,
            relatedTo: relatedTo.length > 0 ? ['or', ...relatedTo] : []
        };

        if (recipeProperties.length > 0) {
          variables.recipeProperties = ['and', ...recipeProperties];
        }

        if (dishTypes.length > 0) {
          variables.dishTypes = ['or', ...dishTypes];
        }

        if (recipeThemes.length > 0) {
          variables.recipeThemes = ['and', ...recipeThemes];
        }

        if (mainIngredients.length) {
          variables.mainIngredients = ['or', ...mainIngredients];
        }
        // Execute the query
        clearTimeout(filterChangeTimer);

        filterChangeTimer = setTimeout(function() {
          self.isLoading = true;
          if (relatedTo.length > 0) {
            self.setHistory();
          } else if (showAll) {
            if (window.history && window.history.pushState) {
              let pageUrl = location.protocol + '//' + location.host + location.pathname;
              let url = pageUrl + '?show-all=true';
              history.pushState(null, null, url);
            }
          }
          executeQuery(self.searchApi, searchQuery, variables, (data) => {
            const dataPath = data.data;
            self.searchResults = dataPath.entries;
            self.pagination.totalCount = dataPath.entryCount;
            self.pagination.totalPages = Math.ceil(Number(dataPath.entryCount) / (self.pagination.elementsPerPage || 24));;
            if (!!data.data.meta) {
              self.allCategories = _.uniq(_.map(_.flatMap(data.data.meta.entries, 'newsCategory'), 'title'));
            }
            if(!self.isReady) {
              self.isReady = true;
            }
            self.isLoading = false;
          });
        }, 800);
      },
      setHistory: function() {
        let self = this;
        let paramString = '';
        if(!!self.searchQuery) {
          paramString += '?q=' + self.searchQuery;
        }
        if(!!_.get(self.searchCategories[0], 'slug')) {
          _.forEach(self.searchCategories, o => {
            paramString += !!paramString ? ('&cat=' + o.slug) : (paramString += '?cat=' + o.slug);
            paramString += !!paramString ? ('&cat-id=' + o.id) : (paramString += '?cat-id=' + o.id);
          })
        }
        if(!_.isEmpty(self.startDate)) {
          paramString += !!paramString ? ('&start=' + moment(self.startDate.start).format('YYYY-MM-DD')) : (paramString += '?start=' + moment(self.startDate.start).format('YYYY-MM-DD'));
          paramString += !!paramString ? ('&end=' + moment(self.startDate.end).format('YYYY-MM-DD')) : (paramString += '?end=' + moment(self.startDate.end).format('YYYY-MM-DD'));
        }
        if (window.history && window.history.pushState) {
          let pageUrl =
            location.protocol + '//' + location.host + location.pathname;
          let url = pageUrl + paramString;
          history.pushState(null, null, url);
        }
      },
      scrollup: function() {
        var top = 0;

        setTimeout(function() {
          window.scrollTo(top, 0);
        }, 100);
        return false;
      },
      updatePagination() {
        const { pagination, currentPage, totalPages } = this;
        pagination.pages = [];
        for (let i = 0; i < totalPages; i++) {
          const index = i + 1;
          pagination.pages.push({
            text: index,
            url: this.getPageUrl(index),
            current: index === currentPage
          });
        }
        pagination.hasNext = currentPage < totalPages;
        pagination.hasPrev = currentPage > 1;
      }
    },
  });
};

!!document.getElementById('searchApp') && searchApp();
