<template>
  <nav aria-label="Pagination">
    <div class="small-12 medium-10 medium-centered large-8 large-centered column">
      <ul v-if="pages > 1" class="pagination mx-auto pt-6 list-none flex items-center justify-center small-text line-height-none">
        <li v-if="current > 1" class="has-previous arrow" @click="changePage(current - 1)">
          <span class="pr-6 inline-block">
            <i class="ion-ios-arrow-back"></i>
          </span>
        </li>
        <template v-for="n in (current - 2, current + 2)" >
          <li :class=" n !== current ? 'not-current' : 'active'" :key="n" v-if="n > 0 && n >= current - 2 && n <= pages" @click="changePage(n)" class="number">
            <span :class=" n === current ? 'current' : 'px-10 text-ebonyClay hover:opacity-50' " :rel="n > current ? 'next' : n < current ? 'prev' : false" class="">{{ n }}</span>
          </li>
        </template>
        <li v-if="current < pages" class="has-next arrow" @click="changePage(current + 1)">
          <span class="pl-6 inline-block">
            <i class="ion-ios-arrow-forward"></i>
          </span>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
export default {
  props: {
    pages: {
      type: Number,
      default: 1,
    },
    current: {
      type: Number,
      default: 1,
    }
  },
  methods: {
    changePage(page) {
      this.$emit('update:current', page)
    }
  },
}
</script>
