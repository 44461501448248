<template>
  <div class="category-search-meta">
    <div class="amount-container">
      <transition name="fade" mode="out-in">
        <div
          class="search-results-loading-text"
          style="z-index: -1"
          v-show="isLoading"
        >
          <div>{{"Searching"|t}}</div>
        </div>
      </transition>
      <div v-if="itemsAmount > 0 && !isLoading">
        <div class="amount-text">
          <span class="total-amount">{{totalItems}}</span><span> {{ 'Recipes'|t }}</span>
        </div>
      </div>
    </div>
    <div v-if="selectedCategories.length > 0" class="category-quick-items">
      <div
        v-for="(item, key) in selectedCategories"
        :key="key"
        @click="categorySelector(item, item.groupTitle, item.siblings);"
        class="category-quick-items__button leading-none button-round mb-3 mr-6 inline-flex flex-no-shrink px-6"
        :class="_.find(selectedCategories, {id: item.id}) ? 'button button--red' : 'button'"
      >
        <span>{{ item.title }}</span>
        <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.875 1L9.875 9M1.875 9L9.875 1L1.875 9Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
    </div>
    <search-sorting
      :sort-by-text="sortByText"
      :sorting.sync="searchSorting"
      :options="sortingOptions"
    ></search-sorting>
  </div>
</template>

<script>
import Vue from 'vue';
import SearchSorting from './SearchSorting'
import {translation} from '../js/translation'

export default {
  name: 'CategorySearchMeta',
  props: {
    totalItems: {
      type: Number,
      default: 0
    },
    itemOffset: {
      type: Number,
      default: 0
    },
    elementsPerPage: {
      type: Number,
      default: 0
    },
    selectedCategories: {
      type: Array,
      default: []
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    'sectionType': {
      type: Array,
      default: []
    },
    sortingOptions: {
      type: Array,
      default: () => [
        {
          title: 'Newest first',
          value: 'postdate desc'
        },
        {
          title: 'Oldest first',
          value: 'postdate asc'
        },
        {
          title: 'Title A-Z',
          value: 'title asc'
        },
        {
          title: 'Title Z-A',
          value: 'title desc'
        }
      ]
    },
    sortByText: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      searchSorting: ''
    }
  },
  computed: {
    _() {
      return _
    },
    itemsAmount: function() {
      const itemsLeft = this.totalItems - this.itemOffset > this.elementsPerPage ? this.itemOffset + this.elementsPerPage : this.totalItems;

      return itemsLeft;
    }
  },
  filters: {
    t(val) {
      const locale = document.getElementById('searchApp').dataset.locale || 'fi-FI';
      const trans = translation[locale];
      return trans[val] || val;
    },
  },
  methods: {
    categorySelector: function(cat, groupCat, childrenParam) {
      let newCats = []
      this.selectedCategories.forEach(element => {
        newCats.push(element)
      })
      if (!_.get(cat, 'id')) {
        newCats = _.reject(newCats, {
          groupTitle: this.categoryFilters[0].groupTitle,
        })
      } else {
        if (_.find(this.selectedCategories, {id: cat.id})) {
          _.remove(newCats, {id: cat.id})
        } else {
          let catObj = {}
          if (!cat) {
          } else {
            catObj.title = _.get(cat, 'title')
            catObj.slug = _.get(cat, 'slug')
            catObj.id = _.get(cat, 'id')
            catObj.level = _.get(cat, 'level')
            catObj.groupTitle = groupCat;
            newCats.push(catObj)
          }
        }
      }
      this.$emit('update:selected-categories', newCats)
      
    },
  },
  watch: {
    searchSorting: function(val) {
      this.$emit('update:searchSorting', val)
    }
  },
  components: {
    SearchSorting
  },
};
</script>
