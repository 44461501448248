<template>
  <section class="product-search">
    <div class="search-container">
      <div class="search-container__inner">
        <div class="input-and-filters">
          <div class="search-input-container ajax-search-container">
            <input 
              v-bind:value="query"
              @input="$emit('update:query', $event.target.value)"
              @keyup="checkKey"
              @focus="isInputFocused = true"
              @blur="onBlur()"
              type="search"
              :placeholder="'Search products'|t"
              :aria-label="'Search products'|t"
              ref="input" 
              class="text-grey-dark autocomplete-search"
              data-type="product"
              @click="closeFilters()"
            >
            <div class="suggestions-background"></div>
            <div class="product-search__button" data-track-category="Recipes search" data-track-label="Submit button" @click="performSearch">
              <i v-if="!isLoading" class="icon-sydanmerkki_search"></i>
              <span v-if="isLoading" class="">
                <div class="cssload-container"><div class="cssload-speeding-wheel"></div></div>
              </span>
            </div>
            <div v-if="!!query" class="product-search__clear-button">
              <span @click="clearInput()">
                <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g transform="translate(-808.000000, -348.000000)" fill="#E10D49" fill-rule="nonzero">
                      <g transform="translate(453.000000, 321.000000)">
                        <g>
                          <path d="M363,24.25 C363.379696,24.25 363.693491,24.5321539 363.743153,24.8982294 L363.75,25 L363.75,34.249 L373,34.25 C373.414214,34.25 373.75,34.5857864 373.75,35 C373.75,35.3796958 373.467846,35.693491 373.101771,35.7431534 L373,35.75 L363.75,35.749 L363.75,45 C363.75,45.4142136 363.414214,45.75 363,45.75 C362.620304,45.75 362.306509,45.4678461 362.256847,45.1017706 L362.25,45 L362.25,35.749 L353,35.75 C352.585786,35.75 352.25,35.4142136 352.25,35 C352.25,34.6203042 352.532154,34.306509 352.898229,34.2568466 L353,34.25 L362.25,34.249 L362.25,25 C362.25,24.5857864 362.585786,24.25 363,24.25 Z" id="Combined-Shape" transform="translate(363.000000, 35.000000) rotate(-315.000000) translate(-363.000000, -35.000000) "></path>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
              </span>
            </div>
          </div>
          <div 
            class="filters-toggle" 
            :class="{'filters-open': filtersOpen}"
            @click="openFilters()"
          >
            <div class="amount-container">
              <svg width="22" height="17" viewBox="0 0 22 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M11 1V16V1Z" fill="#F3F0EC"/>
                <path d="M11 1V16" stroke="#E10D49" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <circle id="middle-circle" cx="11" cy="5" r="2" fill="#F3F0EC" stroke="#E10D49" stroke-width="1.5"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M19 1V16V1Z" fill="#F3F0EC"/>
                <path d="M19 1V16" stroke="#E10D49" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <circle id="right-circle" cx="19" cy="9" r="2" fill="#F3F0EC" stroke="#E10D49" stroke-width="1.5"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M3 1V16V1Z" fill="#F3F0EC"/>
                <path d="M3 1V16" stroke="#E10D49" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <circle id="left-circle" cx="3" cy="12" r="2" fill="#F3F0EC" stroke="#E10D49" stroke-width="1.5"/>
              </svg>
              <div v-if="selectedCategories.length > 0" class="active-amount">
                {{ selectedCategories.length }}
              </div>
            </div>
            <p>{{ 'Filter'|t }}</p>
          </div>
        </div>
        <div v-if="selectedCategories.length > 0 || !!query" class="clear-filters-container">
          <div class="clear-filters" @click="clearSearch">{{ 'Clear search'|t }}<span v-if="selectedCategories.length > 0"> {{ selectedCategories.length }}</span></div>
        </div>
      </div>
    </div>
    <div id="filters" class="filters">
      <div 
        v-if="filtersOpen" 
        class="filters-container bg-white"
      >
        <div class="filters-container-inner">
          <div class="title-and-close">
            <h3>Rajaa tuotteita</h3>
            <span class="close-filters" @click="openFilters()">
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.70703 1.70711L16.5563 16.5563" stroke="#E10D49" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M16.5566 1.70711L1.7074 16.5563" stroke="#E10D49" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </span>
          </div>
          <details
            v-for="(group, key) in categoryFilters"
            :key="key"
            class="filter-group"
            :open="key === 0"
          >
          <summary>
            <p class="filter-group-heading">{{ group.groupTitle }}<span v-if="selectedCatsCount(group) > 0"> {{ selectedCatsCount(group) }}</span></p>
            <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9 1L5.00375 5L1 1" stroke="#E10D49" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </summary>

            <template v-for="(category, key) in group.categories">
              <template v-if="category.children">
                <div :key="key">
                  <div class="parent-category-container">
                    <div
                      @click="categorySelector(category, group.groupTitle, group.handle, category.children, group.multiple);"
                      class="parent-category"
                      :class="{'selected': _.find(selectedCategories, {id: category.id})}">
                      {{ category.title }}
                    </div>
                  </div>
                  <div
                    class="category-item-container"
                    >
                    <div
                      class="category-item"
                      @click="categorySelector(category, group.groupTitle, group.handle, category.children, group.multiple);"
                      :class="_.find(selectedCategories, {id: category.id}) ? 'button button--red' : allCategories.includes(category.title) ? 'button' : 'button opacity-30 pointer-events-none'"
                    >
                      <!-- <svg v-if="_.find(selectedCategories, {id: cat.id})" width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.3333 1L4.99996 8.33333L1.66663 5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg> -->
                      Kaikki tuotteet
                    </div>
                  </div>
                  <div
                    v-for="(child, key) in category.children"
                    :key="key"
                    class="category-item-container"
                    >
                    <div
                      @click="categorySelector(child, group.groupTitle, group.handle, category.children, group.multiple, category.slug);"
                      :id="child.id"
                      :class="_.find(selectedCategories, {id: child.id}) ? 'button button--red' : allCategories.includes(child.title) ? 'button' : 'button opacity-30 pointer-events-none'"
                      class="category-item"
                    >
                      <svg v-if="_.find(selectedCategories, {id: child.id})" width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.3333 1L4.99996 8.33333L1.66663 5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                      {{child.title}}
                    </div>
                  </div>
                </div>
              </template>
              <template v-else>

                <div
                  v-if="key <= getLimit(group.handle, group.categories.length)"
                  :key="key"
                  class="category-item-container"
                  >
                  <div
                    @click="categorySelector(category, group.groupTitle, group.handle, [], group.multiple);"
                    :id="category.id"
                    :class="_.find(selectedCategories, {id: category.id}) ? 'button button--red' : allCategories.includes(category.title) ? 'button' : 'button opacity-30 pointer-events-none'"
                    class="category-item"
                  >
                    <svg v-if="_.find(selectedCategories, {id: category.id})" width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12.3333 1L4.99996 8.33333L1.66663 5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    {{category.title}}
                  </div>
                </div>

              </template>
            </template>
            <template v-if="group.handle == 'kiltaCustomers' && group.categories.length > getLimit(group.handle, group.categories.length) + 1 && moreCustomers">
              <div
                @click="moreCustomers = false"
                class="category-item category-item-more leading-none button-round mb-3 mr-6 inline-flex flex-no-shrink px-6 button opacity-30 pointer-events-none"
                >
                Näytä lisää
              </div>
            </template>
          </details>
        </div>
        <div class="filter-actions-container">
          <div class="button button--red button--square button--transparent" @click="openFilters()">
            Näytä tuotteet <span v-if="totalItems > 0">{{ `(${totalItems})` }}</span>
          </div>
          <div v-if="selectedCategories.length > 0 || !!query" class="clear-filters-container clear-filters-container-mobile">
            <div class="clear-filters" @click="clearSearch">{{ 'Clear search'|t }}<span v-if="selectedCategories.length > 0"> {{ selectedCategories.length }}</span></div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!showAll && totalItems === 0 && _.isEmpty(query) && selectedCategories.length === 0" class="show-all-button-container">
      <div class="show-all-button" @click="performSearch(false, true)">{{ 'Show all'|t }} <span class="show-all-amount">{{ totalEntries }}</span> {{ 'products'|t }}</div>
    </div>
  </section>
</template>

<script>
import _ from 'lodash'
import {translation} from '../../js/translation'

export default {
  name: 'CategoryFilter',
  props: {
    query: {
      type: String,
      default: '',
    },
    sectionType: {
      type: Array,
      default: [],
    },
    categoryFilters: {
      type: Array,
      default: () => [],
    },
    selectedCategory: {
      type: Object,
      default: () => ({
        title: {
          type: String,
        },
        slug: {
          type: String,
        },
        id: {
          type: String,
        },
        groupTitle: {
          type: Object,
        },
      }),
    },
    allCategories: {
      type: Array,
      default: () => [],
    },
    selectedCategories: {
      type: Array,
      default: () => [],
    },
    sorting: {},
    currentParentCat: {},
    sortingOptions: {
      type: Array,
      default: () => [
        {
          title: 'Newest first',
          value: 'postdate desc',
        },
        {
          title: 'Oldest first',
          value: 'postdate asc',
        },
        {
          title: 'Title A-Z',
          value: 'title asc',
        },
        {
          title: 'Title Z-A',
          value: 'title desc',
        },
      ],
    },
    totalItems: {
      type: Number,
      default: 0,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    totalEntries: {
      type: Number,
      default: 0
    },
    filtersOpen: {
      type: Boolean,
      default: false,
    },
    showAll: {
      type: Boolean,
      default: false,
    }
  },
  data () {
    return {
      isInputFocused: false,
      moreCustomers: true
      // parentCat: this.currentParentCat
    }
  },
  created: function() {
  },
  mounted: function() {
    this.toggleCat()
  },
  updated: function() {},
  computed: {
    _() {
      return _
    }
  },
  filters: {
    t(val) {
      const locale = document.getElementById('productSearchApp').dataset.locale || 'fi-FI';
      const trans = translation[locale];
      return trans[val] || val;
    },
  },
  methods: {
    getLimit: function(handle, count) {
      const self = this;
      let limit = count;
      if (handle == 'kiltaCustomers') {
        limit = (self.moreCustomers) ? 10 : count;
      }
      return limit;
    },
    onBlur: function() {
      setTimeout(() => {
        this.isInputFocused = false
      }, 1);
    },
    clearInput: function() {
      this.$emit('update:query', '')
      this.$emit('clear')
    },
    filtersAmount: function(group) {
      return _.filter(
        this.selectedCategories,
        o => _.get(o, 'groupTitle') === group.groupTitle,
      ).length
    },
    selectedCatsCount: function (group) {
      return _.filter(this.selectedCategories, {'groupTitle': group.groupTitle}).length;
    },
    categorySelector: function(cat, groupCat, groupHandle, children, multiple, parentCat) {
      const self = this;
      let newCats = []
      if (parentCat || (groupCat && (groupCat === 'Valmistaja' || groupCat === 'Ominaisuudet'))) {
        if (groupCat !== 'Valmistaja' && groupCat !== 'Ominaisuudet') {
          // self.$emit('update:current-parent-cat', parentCat)
        }
        // "clone" the array to avoid issues with Vue's change detection
        this.selectedCategories.forEach(element => {
          newCats.push(element)
          // if ((element.groupTitle && (element.groupTitle === 'Valmistaja' || element.groupTitle === 'Ominaisuudet'))) {
          //   newCats.push(element)
          // }
          // if (((parentCat && element.parent) && (element.parent.slug === parentCat))) {
          //   newCats.push(element)
          // }
          // if ((groupCat && (groupCat === 'Valmistaja' || groupCat === 'Ominaisuudet')) && (element.groupTitle === 'Tuoteryhmä')) {
          //   newCats.push(element);
          // }
        })
      }  else {
        if ((groupCat !== 'Valmistaja' && groupCat !== 'Ominaisuudet')) {
          self.$emit('update:current-parent-cat', cat.slug)
          this.selectedCategories.forEach(element => {
            if ((element.groupTitle && (element.groupTitle === 'Valmistaja' || element.groupTitle === 'Ominaisuudet'))) {
              newCats.push(element)
            }
          })
        }
      }

      if (!_.get(cat, 'id')) {
      } else {
        if (_.find(self.selectedCategories, {id: cat.id})) {
          _.remove(newCats, {id: cat.id})
        } else {
          

          if (!multiple) {
            _.remove(newCats, {groupTitle: groupCat});
          }

          let catObj = {}
          catObj.title = _.get(cat, 'title')
          catObj.slug = _.get(cat, 'slug')
          catObj.id = _.get(cat, 'id')
          catObj.level = _.get(cat, 'level')
          catObj.parent = _.get(cat, 'parent')
          if (children) {
            catObj.siblings = children;
          }
          catObj.groupHandle = !!groupHandle ? groupHandle : undefined
          catObj.groupTitle = !!groupCat ? groupCat : undefined
          newCats.push(catObj)
        }
      }
      
      self.$emit('update:selected-categories', newCats)
      
    },
    clearSearch: function() {
      this.$emit('update:selected-categories', [])
      this.$emit('update:current-parent-cat', {})
      this.$emit('update:query', '')
      this.$emit('clear')
    },
    scrollToFilters: function() {
      const element = document.querySelector('.filters')
      const offset = 200
      const bodyRect = document.body.getBoundingClientRect().top
      const elementRect = element.getBoundingClientRect().top
      const elementPosition = elementRect - bodyRect
      const offsetPosition = elementPosition - offset

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      })
    },
    toggleCat: function() {
      // assign HTMLCollection with parents of images with objectFit to variable
      const toggleContainer = document.querySelectorAll(
        '[data-toggle-container]',
      )

      // Loop through HTMLCollection
      for (let i = 0; i < toggleContainer.length; i++) {
        const toggle = toggleContainer[i].querySelector('[data-toggle]')

        if (toggle !== null) {
          toggle.onclick = function(e) {
            e.preventDefault()
            const toggleItems = toggleContainer[i].querySelectorAll(
              '[data-toggle-class]',
            )

            for (let j = 0; j < toggleItems.length; j++) {
              const toggleClass = toggleItems[j].getAttribute(
                'data-toggle-class',
              )

              if (toggleItems[j].classList) {
                toggleItems[j].classList.toggle(toggleClass)
              } else {
                var classes = toggleItems[j].className.split(' ')
                var existingIndex = classes.indexOf(toggleClass)

                if (existingIndex >= 0) classes.splice(existingIndex, 1)
                else classes.push(toggleClass)

                toggleItems[j].className = classes.join(' ')
              }
            }

            // if toggle text is found, toggle the text
            if (toggle.querySelector('[data-toggle-text]') !== null) {
              const toggleText = toggle.querySelector('[data-toggle-text]')
              const originalText = toggleText.innerHTML
              const newText = toggleText.getAttribute('data-toggle-text')

              toggleText.innerHTML = newText
              toggleText.setAttribute('data-toggle-text', originalText)
            }
          }
        }
      }
    },
    focusInput: function() {
      this.$refs.input.focus();
    },
    openFilters: function () {
      this.$emit('update:filters-open', !this.filtersOpen)
      if (this.filtersOpen) {
        this.scrollToFilters();
      }
    },
    closeFilters: function () {
      this.$emit('update:filters-open', false)
    },
    performSearch: function(init, showAll) {
      this.$emit('update:loading', true)
      this.$emit('search', init, showAll)
    },
    checkKey: function(e) {
      if (e.keyCode === 13) {
        this.performSearch(true);
      }
    }
  },
}
</script>
